<template>
    <canvas ref="canvas" :width="width" :height="height" :style="canvasStyle"></canvas>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import qrcode from 'qrcode'

export default defineComponent({
    name: 'Namecard',
    props: {
        avatar_image_url: {
            type: String,
            required: true,
        },
        canvasWidth: {
            type: Number,
            required: true,
        },
        canvasHeight: {
            type: Number,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: 'rgb(38, 70, 105)',
        },
        size: {
            type: Number,
            default: 200,
        },
        url: {
            type: String,
            required: true,
        },
        rotation: {
            type: Number,
            default: 0,
        },
        shouldRotate: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        canvasStyle() {
            if (this.shouldRotate) {
                return {
                    transform: `rotate(90deg) translateY(-${this.height}px)`,
                    transformOrigin: 'top left',
                }
            }
            return {
                'width': '100%'
            }
        },
        width() {
            return Math.max(this.canvasHeight, this.canvasWidth)
        },
        height() {
            return Math.min(this.canvasHeight, this.canvasWidth)
        },
    },
    mounted() {
        this.draw()
    },
    watch: {
        canvasHeight(val) {
            this.draw()
        },
        // canvasWidth(val) {
        //     this.draw()
        // }
    },
    methods: {
        async draw() {
            console.log('draw');
            const canvas = this.$refs.canvas as HTMLCanvasElement | null
            if (canvas) {
                const ctx = canvas.getContext('2d')
                if (ctx) {
                    let rotation = 0;
                    const size = this.size;
                    let avatarX = 100;
                    let avatarY = canvas.height / 2 - size / 2;
                    let qrX = canvas.width - avatarX - size;
                    let qrY = avatarY;

                    ctx.fillStyle = this.backgroundColor!
                    ctx.fillRect(0, 0, this.width!, this.height!)

                    // Save the context state before rotation
                    ctx.save()

                    // Rotate the canvas based on the rotation prop
                    const radians = rotation * (Math.PI / 180)
                    const centerX = canvas.width / 2
                    const centerY = canvas.height / 2
                    ctx.translate(centerX, centerY)
                    ctx.rotate(radians)
                    ctx.translate(-centerX, -centerY)

                    // Load the avatar image from the URL and paste it onto the namecard
                    const avatarImage = await this.loadImage(this.avatar_image_url)

                    ctx.drawImage(avatarImage, avatarX, avatarY, size, size)

                    // Generate the QR code image with white color and transparent background
                    const qrDataURL = await this.generateQRCode(this.url, size)
                    const qrImage = await this.loadImage(qrDataURL)


                    ctx.drawImage(qrImage, qrX, qrY, size, size)

                    // Restore the context state after rotation
                    ctx.restore()
                }
            }
        },
        async loadImage(src: string) {
            return new Promise<HTMLImageElement>((resolve, reject) => {
                const image = new Image()
                image.onload = () => resolve(image)
                image.onerror = (err) => reject(err)
                image.src = src
            })
        },
        async generateQRCode(data: string, size: number) {
            return new Promise<string>((resolve, reject) => {
                qrcode.toDataURL
                    (
                        data,
                        {
                            errorCorrectionLevel: 'H',
                            width: size,
                            margin: 0,
                            color: {
                                dark: '#FFF',
                                light: '#0000',
                            },
                            rendererOpts: {},
                        },
                        (err, url) => {
                            if (err) reject(err)
                            else resolve(url)
                        }
                    )
            })
        },
    },
})
</script>