<template>
    <name-card :avatar_image_url="profile.avatar_image_url || profile.avatar" :url="url" v-if="profile.id"
        :canvasHeight="canvasHeight" :canvasWidth="canvasWidth" :shouldRotate="shouldRotate" />
</template>
<script lang="ts">
import { useWindowSize } from '@vueuse/core'
import { defineComponent } from 'vue';
import instance from '../axios';

export default defineComponent({
    data() {
        return {
            profile: {
                id: undefined,
                name: null,
                avatar: undefined,
                avatar_image_url: undefined,
                description: null,
                links: [{
                    url: undefined,
                    icon: null,
                    name: null
                }],
                background_color: undefined,
            },
            loading: true,
        }
    },
    computed: {
        url() {
            return `${window.location.origin}/user/${this.$route.params.id}`;
        },
        shouldRotate() {
            return this.canvasWidth < this.canvasHeight;
        }
    },
    mounted() {
        console.log(this.$route);
        this.init();
    },
    methods: {
        async init() {
            const url = `/api/profile/?id=${this.$route.params?.id}`;
            const { data } = await instance.get(url);
            if (data.data) {
                this.profile = data.data;
                document.title = `${this.profile.name} | Web3 Social Profile`;
            }
            this.loading = false;
        }
    },
    setup() {
        const { width, height } = useWindowSize()

        return {
            canvasWidth: width,
            canvasHeight: height
        }
    }
})
</script>