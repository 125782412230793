<template>
    <div>
        <div>
            <div>Your profile has been successfully updated. You can now view your profile by clicking on the URL below.
            </div>
            <div>
                URL:
                <router-link :to="url" class="text-blue-400">{{ url }}</router-link>
            </div>
            <div class="mt-4">
                <div>Here are the details of your NFC card:</div>
                <div class="font-bold text-md">NFC Card Cover:</div>
                <el-image src="https://i.imgur.com/JQnhBVZ.png" />
                <div class="font-bold text-md">NFC Card Background:</div>
                <div class="w-full">
                    <name-card :avatar_image_url="form.avatar_image_url" :url="url" v-if="form.avatar_image_url"
                        canvasWidth="600" canvasHeight="400" :size="150" />
                </div>

                <!-- <el-image :src="nameCardImage" /> -->
            </div>
            <div class="mt-4">Contact @calpaliu on Twitter to get your NFC card.</div>
        </div>

    </div>
</template>

<script lang="ts">
// import { ref } from 'vue';

export default {
    name: 'SuccessDialog',
    props: {
        form: {
            type: Object,
            required: true
        },
        userURL: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true,
        },
        nameCardImage: {
            type: String,
            required: true,
        },
    },
    data(vm) {
        return {
            dialogVisible: false,
        }
    },
};
</script>