import axiosRetry from 'axios-retry';
import axios from 'axios';
import { auth0 } from './auth0';

const instance = axios.create({
    // timeout: 10000,
    baseURL: 'https://web3-express.vercel.app'
    // baseURL: `https://web3-profile-backend.calpa.workers.dev`,
    // baseURL: `http://0.0.0.0:8787/`
    // baseURL: `http://localhost:3000/`
});

instance.interceptors.request.use(async (config) => {
    const path = window.location.pathname;
    if (!path.startsWith('/user')) {
        const token = await auth0.getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


axiosRetry(instance, {
    retries: 10,
    shouldResetTimeout: true,
    retryCondition: err => err.code === 'ECONNABORTED' || axiosRetry.isNetworkOrIdempotentRequestError(err)
});

export default instance;