<template>
  <custom-navbar />
  <div class="page py-10" :style="{ 'background-color': form?.background_color }">
    <el-card class="mx-auto my-auto max-w-screen-lg">
      <template #header>
        <div class="flex justify-between">
          <div class="text-2xl font-bold">
            Profile
          </div>
          <div>
            <el-button @click="$router.push(userURL)">View</el-button>
            <!-- <el-button @click="logout">Logout</el-button> -->
          </div>
          <!-- <w3m-core-button></w3m-core-button> -->
        </div>
      </template>
      <div class="">
        <!-- <div class="text-sm mb-4">
          Nickname: {{ user.nickname }}
        </div> -->
        <el-form :model="form" label-width="160px" label-position="left" ref="formRef" :rules="rules" v-loading="loading">
          <el-form-item label="Address" required class="form-item" prop="id">
            <el-input :value="account?.address" placeholder="Address" v-if="isConnected" disabled />
            <!-- <el-input v-model="form.id" placeholder="calpaliu.eth" @blur="handleAddressInput(form.id)"
            v-else="isConnected" /> -->
          </el-form-item>
          <!-- <div v-if="ensName">
          {{ ensName }}
        </div>
        <div v-else class="text-sm">No ENS Name</div> -->

          <el-form-item label="Profile Title" required class="form-item" prop="name">
            <el-input v-model="form.name" placeholder="Your Name" />
          </el-form-item>

          <el-form-item label="NFTs">
            <div class="flex flex-row flex-wrap" v-if="nfts.ownedNfts?.length > 0">
              <template v-for="(nft, index) in nfts.ownedNfts">
                <el-image :src="nft.media[0].gateway" v-if="nft.media[0].raw" class="w-40 my-4 mx-4 cursor-pointer"
                  @click="form.avatar_image_url = nft.media[0].gateway" :class="getNFTClasses(nft)" />
              </template>
            </div>
          </el-form-item>
          <el-form-item label="Avatar" class="form-item" required prop="avatar_image_url">
            <el-input v-model="form.avatar_image_url" placeholder="Image URL" />
          </el-form-item>

          <img :src="form?.avatar_image_url" v-if="form?.avatar_image_url" class="w-40 mx-auto mb-4" />
          <!-- <el-form-item label="Avatar Image" :rules="[
          {
            type: 'url',
            message: 'Please enter a valid URL',
            trigger: ['blur', 'change']
          }
        ]" prop="avatar_image_url" class="form-item">
          <el-input v-model="form.avatar_image_url" placeholder="Image URL" />
        </el-form-item> -->

          <el-form-item label="Email" class="form-item" prop="email" :rules="[
            { type: 'email', message: 'Please enter a valid email address.', trigger: 'blur' }
          ]">
            <el-input v-model="form.email" placeholder="your_name@gmail.com" />
          </el-form-item>

          <el-form-item label="Description" class="form-item">
            <el-input v-model="form.description" />
          </el-form-item>

          <el-form-item label="Background Color" class="form-item" required>
            <el-color-picker v-model="form.background_color" show-alpha :predefine="predefineColors" size="large" />
          </el-form-item>
          <!-- </client-only> -->

          <div class="mb-4 font-bold">
            Links
          </div>
          <el-card v-for="(link, index) of form.links" class="mb-4 relative">
            <el-button type="danger" class="absolute right-5" @click="removeLink(index)"
              :disabled="index === 0">-</el-button>
            <el-form-item label="Name" class="form-item" required :prop="'links[' + index + '].name'" :rules="[{
              required: true,
              message: 'Please enter the name.',
              trigger: 'blur'
            }]">
              <el-input v-model="link.name" placeholder="Name" />
            </el-form-item>

            <el-form-item label="URL" class="form-item" :rules="[
              {
                type: 'url',
                message: 'Please enter a valid URL',
                trigger: ['blur', 'change']
              }
            ]" :prop="'links[' + index + '].url'">
              <el-input v-model="link.url" placeholder="URL" />
            </el-form-item>

            <el-form-item label="Icon">
              <el-radio-group v-model="link.icon">
                <el-radio v-for="(value, key) of icons" :label="value">
                  <font-awesome-icon :icon="value" size="2x" class="icon" v-if="value" />
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-card>

          <div class="mb-4 text-right">
            <el-button @click="addLink">+</el-button>
          </div>

          <!-- <div v-if="form.id" class="mb-4 text-right">
          Your URL is: <span class="text-blue-600">{{ url }}</span>
        </div> -->

          <div class="text-right">
            <el-button @click="submit" type="primary" :loading="submitButtonLocked">
              Submit
            </el-button>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>

  <el-dialog v-model="dialogVisible" title="Congratulations!" :width="dialogWidth" :center="true" top="7vh">
    <success-dialog :form="form" :userURL="userURL" :url="url" :nameCardImage="nameCardImage" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('update:dialogVisible', false)">Continue Editing</el-button>
        <el-button type="primary" @click="$router.replace(userURL)">View Profile</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import SuccessDialog from '../components/success-dialog.vue';
import CustomNavBar from '../components/custom-navbar.vue';
import * as Sentry from '@sentry/vue';
import { ethers } from 'ethers';
// import slugify from 'slugify';
import { defineComponent } from 'vue';
import type { FormInstance } from 'element-plus'
import { ElNotification } from 'element-plus'

// import { fetchEnsName } from '@wagmi/core'

import instance from '../axios';
import { useAuth0 } from '@auth0/auth0-vue';

const icons = {
  nothing: '',
  github: 'fa-brands fa-github',
  twitter: 'fa-brands fa-twitter',
  facebook: 'fa-brands fa-facebook',
  linkedin: 'fa-brands fa-linkedin',
  instagram: 'fa-brands fa-instagram',
  telegram: 'fa-brands fa-telegram',
}

export default defineComponent({
  components: {
    CustomNavBar,
    SuccessDialog,
  },
  setup() {
    const { user } = useAuth0();

    const cloudflareProvider = new ethers.providers.CloudflareProvider();

    return {
      icons, cloudflareProvider, user,
    }
  },
  mounted() {
    const address = this.user.sub?.replaceAll('%3A', ':').split(':').pop();
    console.log(this.user.sub);
    if (address) {
      this.account.address = address;
      this.form.id = address;
      this.getNFTs(address);
    }

    this.getProfile();
  },
  computed: {
    userURL(): string {
      return `/user/${this.form.id}`;
    },
    url(): string {
      return `${window.location.origin}/user/${this.form.id}`;
    },
    isConnected(): boolean {
      return Boolean(this.account?.address);
    },
    dialogWidth(): string {
      if (window.innerWidth < 768) {
        return '100%'
      }
      return '50%';
    }
  },
  watch: {
    'account.address'(val) {
      if (val) {
        this.getNFTs(val);
      } else {
        this.nfts = {
          ownedNfts: [],
        }
      }

    }
  },
  data() {
    return {
      nameCardImage: '',
      ensName: '',
      loading: false,
      dialogVisible: false,
      web3modalState: {},
      submitButtonLocked: false,
      account: {
        address: '' as string | undefined,
        connector: null as any,
        isConnected: false,
        isConnecting: false,
        isDisconnected: true,
        isReconnecting: false,
      },
      rules: {
        avatar_image_url: {
          required: true,
          message: 'Please select the NFT or input the image URL',
          trigger: ['blur', 'change']
        }
      },
      form: {
        id: '',
        background_color: 'rgb(254 226 226)',
        avatar_image_url: '',
        name: '',
        email: '',
        description: '',
        links: [{
          name: "",
          url: "",
          icon: "",
        }],
      },
      nfts: {
        ownedNfts: [] as Array<any>,
      },
      predefineColors: [
        'rgb(254 226 226)',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577',
      ]
    }
  },
  methods: {
    // async getNameCard() {
    //   const { data } = await instance.post('https://eoxefbbfdlze2im.m.pipedream.net', {
    //     ...this.form,
    //     url: this.url,
    //   });
    //   this.nameCardImage = data.data_url;
    // },

    getNFTClasses(nft: any) {
      if (nft.media[0].gateway === this.form.avatar_image_url) {
        return 'selected'
      }
    },
    // async getEnsName(address: `0x{string}`) {
    //   const ensName = await fetchEnsName({ address, chainId: 1 });
    //   console.log(`ensName: ${ensName}`);
    //   if (ensName) {
    //     this.ensName = ensName;
    //   }

    //   return ensName;
    // },
    async getNFTs(address: string) {
      const { data } = await instance.get(`/api/auth/nft`, {
        params: {
          address,
        }
      });

      this.nfts = data;
    },
    async submit() {
      const formEl: FormInstance | undefined = this.$refs.formRef as FormInstance | undefined;
      if (!formEl) {
        return;
      }
      formEl.validate(async (valid) => {
        if (valid) {
          this.submitButtonLocked = true;
          await this.saveProfile();
          // await this.getNameCard();
        } else {
          console.log('error submit!')
          return false
        }
      })
    },
    async getProfile() {
      this.loading = true;
      const { data } = await instance.get(`/api/profile?id=${this.form.id}`);
      if (data['data']) {
        this.form = data['data'];
        if (!this.form.background_color) {
          this.form.background_color = 'rgba(254, 226, 226, 1)';
        }
      }
      // if (this.user.nickname) {
      //   this.form.id = this.user.nickname;
      // }
      // this.getEnsName(this.user.nickname);
      if (!Array.isArray(this.form.links)) {
        this.form.links = [{
          name: "",
          url: "",
          icon: "",
        }];
      }

      this.loading = false;
    },
    async saveProfile() {
      try {
        console.log(this.form);
        const id = this.form.id;

        const { data } = await instance.put(`/api/auth/profile/?id=${id} `, this.form, {
          timeout: 10000
        });
        if (data.success) {
          this.dialogVisible = true;
          this.submitButtonLocked = false;
        } else {
          ElNotification.error({
            title: 'Error',
            message: data.message,
            duration: 2000
          })
          this.submitButtonLocked = false;
        }
      } catch (error) {
        Sentry.captureException(error);
        ElNotification.error({
          title: 'Error',
          message: JSON.stringify(error),
          // duration: 2000,
        });
        console.error(error);
        this.submitButtonLocked = false;

      }
    },
    addLink() {
      this.form.links.push({
        name: '',
        url: '',
        icon: '',
      });
    },
    removeLink(index: number) {
      if (index !== -1) {
        this.form.links.splice(index, 1);
      }
    }
  }
})
</script>
<style lang="scss" scoped>
.page {
  @apply bg-red-100;
  // @apply overflow-scroll;
  // @apply w-full;
}

.form-item {
  @media (min-width: 1024px) {
    @apply w-[80%];
  }
}

.selected {
  border: 5px dotted red;
}
</style>