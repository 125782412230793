<template>
    <custom-navbar />
    <div class="flex flex-1 flex-col mb-10 md:mb-0" v-if="!isLoading">
        <main class="pt-10 mx-auto max-w-7xl h-full flex items-center">
            <div class="text-center">
                <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span class="block">Welcome to</span>
                    <span class="block text-indigo-600">Web3 Social Profile</span>
                </h1>
                <router-link to="/user/calpaliu.eth">
                    <img src="https://i.imgur.com/WPce7j7.png" class="h-[50vh] mx-auto my-4" />
                </router-link>
                <p
                    class="text-left px-4 mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                    Create and customize your social profile using your wallet.
                </p>
                <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                    <template v-if="isAuthenticated">
                        <router-link to="/form" v-slot="{ navigate }" custom>
                            <el-button type="primary" @click="navigate" role="link"
                                class="umami--click--home-edit-profile-button">Edit Your
                                Profile</el-button>
                        </router-link>
                    </template>

                    <template v-else>
                        <div class="rounded-md shadow">
                            <el-button @click="login" class="umami--click--home-sign-in-button" type="primary">
                                Sign In
                            </el-button>
                        </div>
                    </template>

                </div>
            </div>
        </main>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import CustomNavBar from '../components/custom-navbar.vue';

export default defineComponent({
    components: {
        CustomNavBar
    },
    setup() {
        const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

        return {
            login: () => {
                loginWithRedirect({
                    authorizationParams: {
                        redirect_uri: `${window.location.origin}/form`,
                    }
                });
            },
            isLoading,
            isAuthenticated
        };
    },
    // mounted() {
    // if (this.isAuthenticated) {
    //     this.$router.replace('/form');
    // }
    // },
    // watch: {
    //     isAuthenticated(val) {
    //         if (val === true) {
    //             this.$router.replace('/form');
    //         }
    //     }
    // }
});
</script>