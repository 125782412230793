<template>
    <custom-navbar />
    <div class="flex-grow container mx-auto px-4 py-8 sm:py-12">
        <h1 class="text-3xl font-bold text-gray-800 mb-4">About Web3 Crypto Social Profile</h1>

        <div class="flex flex-col space-y-4 text-lg leading-relaxed text-gray-800">
            <p>Thank You!</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomNavbar from '../components/custom-navbar.vue';

export default defineComponent({
    components: {
        CustomNavbar
    }
});
</script>