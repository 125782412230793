<template>
    <!-- <custom-navbar /> -->
    <el-container class="page" v-loading="loading" :style="{
        'background-color': profile?.background_color,
    }">
        <div v-if="!loading && profile?.id" class="relative max-w-screen-md w-full flex flex-col items-center">
            <el-button @click="$router.push('/form')" v-if="isAuthenticated" class="absolute right-12" circle>
                <icon-mdi-pencil />
            </el-button>
            <el-button @click="$router.push({
                name: 'CardBack',
                params: {
                    id: $route.params.id,
                }
            })" class="absolute right-0" circle>
                <icon-material-symbols-qr-code />
            </el-button>

            <img :src="profile.avatar_image_url || profile.avatar" class="avatar" alt="Avatar">
            <div class="font-bold text-xl mt-4 break-words max-w-[80vw]">
                {{ profile.name }}
            </div>
            <div class="font-bold text-lg text-gray-600 mb-10">
                {{ profile?.description }}
            </div>
            <template v-for="(link, index) in profile.links" :key="link.url">
                <div class="w-full animate__animated animate__fadeIn" :class="`animate__delay-${index}s`"
                    style="--animate-delay: 0.5s;">
                    <a class="row" :href="link.url" target="_blank" :style="getRowStyle(index)" v-if="link.url">
                        <font-awesome-icon :icon='link.icon' size="2x" class="icon" v-if="link.icon" />
                        {{ link.name }}
                    </a>
                    <div class="row cursor-default w-full" v-else-if="link.name">
                        <font-awesome-icon :icon='link.icon' size="2x" class="icon" v-if="link.icon" />
                        {{ link.name }}
                    </div>
                </div>
            </template>
        </div>
        <div class="mt-auto flex flex-col items-center justify-center">
            <router-link to="/" class="mb-4">
                <img src="/logo.svg" class="w-[30px]" alt="Logo" />
            </router-link>
        </div>
    </el-container>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

import instance from '../axios';
import CustomNavBar from '../components/custom-navbar.vue';
import NameCard from '../components/name-card.vue';

export default defineComponent({
    components: {
        CustomNavBar,
        NameCard
    },
    setup() {
        const { isAuthenticated } = useAuth0();
        return {
            isAuthenticated
        }
    },
    data() {
        return {
            showNameCard: false,
            profile: {
                id: undefined,
                name: null,
                avatar: undefined,
                avatar_image_url: undefined,
                description: null,
                links: [{
                    url: undefined,
                    icon: null,
                    name: null
                }],
                background_color: undefined,
            },
            loading: true,
        }
    },
    computed: {
        url() {
            return window.location.href;
        }
    },
    mounted() {
        console.log(this.$route);
        this.init();
    },
    methods: {
        getRowStyle(index: number) {
            return 'animation-duration: ' + (300 + index * 500) + 'ms;';
        },
        async init() {
            const url = `/api/profile/?id=${this.$route.params?.id}`;
            const { data } = await instance.get(url);
            if (data.data) {
                this.profile = data.data;
                document.title = `${this.profile.name} | Web3 Social Profile`;
            }
            this.loading = false;
        }
    }
});
</script>

<style lang="scss" scoped>
.avatar {
    @apply rounded-full w-24;
}

.page {
    @apply h-[100vh] flex items-center flex-col;
    // @apply bg-red-100;
    @apply p-5;

    @media (min-width: 640px) {
        @apply pt-20;
    }
}

.row {
    z-index: 0;
    overflow: hidden;
    margin-bottom: 16px;
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    color: rgb(0, 0, 0);
    transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
    box-shadow: rgb(10 11 13 / 8%) 0px 2px 4px 0px;
    border-radius: 4px;

    @apply h-16 flex items-center justify-center px-5;
    @apply w-full;

    // @media (min-width: 640px) {
    // @apply w-[50%];
    // }


    // animation: fade-in 1s ease-in-out forwards;

    // @keyframes fade-in {
    //     0% {
    //         // position: absolute;
    //         opacity: 0;
    //     }

    //     100% {
    //         // position: relative;
    //         opacity: 1;
    //     }
    // }

    &:hover {
        transform: scale(1.05);
    }

    position: relative;
}

.icon {
    position: absolute;
    left: 5%;
}
</style>