// main.ts
import { createApp } from 'vue'
// import { createAuth0 } from '@auth0/auth0-vue';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import 'animate.css';

// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './style.css';

import router from './router';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

import { faGithub, faTwitter, faTelegram, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';

library.add(faGithub, faTwitter, faTelegram, faPen, faFacebook, faInstagram, faLinkedin);

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { auth0 } from './auth0';

const app = createApp({});

Sentry.init({
    app,
    dsn: "https://04e3ab3e71ca4bef8ad961bb724cadbf@o4504825596018688.ingest.sentry.io/4504825597132800",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "profile.calpa.me", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

app.use(auth0);

// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//     app.component(key, component)
// }

app.use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    // .use(ElementPlus)
    .mount('#app');