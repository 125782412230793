<template>
    <div class="bg-white sticky top-0 z-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
            <div class="flex items-center border-b-2 border-gray-100 md:justify-start md:space-x-10 py-6">
                <div class="lg:w-0 lg:flex-1">
                    <router-link to="/" class="flex items-center">
                        <img class="h-8 w-auto sm:h-10" src="/logo.svg" alt="Logo">
                        <span class="text-gray-900 font-medium ml-3">Web3 Social Profile</span>
                    </router-link>
                </div>
                <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                    <nav class="hidden md:flex space-x-10">
                        <router-link to="/form" v-slot="{ navigate, href, isActive }" custom>
                            <el-link :type="isActive ? 'danger' : 'primary'" @click="navigate" :href="href"
                                :underline="false">
                                Edit Profile
                            </el-link>
                        </router-link>
                        <router-link to="/about" v-slot="{ navigate, href, isActive }" custom>
                            <el-link :type="isActive ? 'danger' : 'primary'" @click="navigate" :href="href"
                                :underline="false">
                                About
                            </el-link>
                        </router-link>
                    </nav>
                    <el-button @click="login" v-if="!isAuthenticated"
                        class="umami--click--navbar-sign-in-button ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                        Sign In
                    </el-button>
                    <template v-if="isAuthenticated">
                        <el-button @click="logout"
                            class="umami--click--navbar-log-out-button ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-gray-400 bg-white hover:bg-gray-50">
                            Log out
                        </el-button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default defineComponent({
    name: 'CustomNavbar',
    setup() {
        const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();

        return {
            login: () => {
                loginWithRedirect({
                    authorizationParams: {
                        redirect_uri: `${window.location.origin}/form`,
                    }
                });
            },
            isLoading,
            isAuthenticated,
            logout: () => {
                logout({
                    logoutParams: {
                        returnTo: window.location.origin
                    }
                })
            }
        };
    },
})
</script>