import { createRouter, createWebHistory } from 'vue-router';
import { authGuard } from "@auth0/auth0-vue";

import HomePage from './containers/HomePage.vue';
import SocialProfileForm from './containers/SocialProfileForm.vue';
import SocialProfile from './containers/SocialProfile.vue';
import AboutPage from './containers/AboutPage.vue';
import CardBack from './containers/CardBack.vue';
// const HomePage = () => import('./containers/HomePage.vue');
// const SocialProfileForm = () => import('./containers/SocialProfileForm.vue');
// const SocialProfile = () => import('./containers/SocialProfile.vue');
// const AboutPage = () => import('./containers/AboutPage.vue');

const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/about',
        component: AboutPage,
    },
    {
        path: '/form', component: SocialProfileForm,
        beforeEnter: authGuard,
    },
    {
        path: '/user/card/:id(.*)*',
        component: CardBack,
        name: 'CardBack'
    },
    {
        path: '/users/:id(.*)*',
        component: SocialProfile,
    },
    {
        path: '/user/:id(.*)*',
        component: SocialProfile,
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: SocialProfile },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;